// variables
//media queries
$phone: "screen and (max-width: 420px)";
$tablet: "screen and (min-width: 421px) and (max-width: 786px)";
$computer: "screen and (min-width: 787px) and (max-width: 1200px)";
$lg-computer: "screen and (min-width: 1200px)";

//e.g h2 {
//    border-bottom: 1px solid $skf-steel;
//    @media #{$computer} {
//      border-bottom: none;
//    }
//}

//Primary Colours
$skf-blue: #0f58d6;
$skf-red: #eb202a;
$skf-white: #fff;
$skf-clay: #47596d;
$skf-forest: #41645f;
$skf-earth: #776251;
$skf-stone: #5e5e63;
$skf-sky: #d6f0f3;
$skf-mist: #e4f3eb;
$skf-sand: #ecece0;
$skf-steel: #dce0e1;
$skf-cloud: #eaf0f0;
$skf-yellow: #fed814;
$skf-green: #83c215;
$skf-orange: #ff7f00;
$skf-purple: #7a0d93;

//More

$skf_primary: #0F58D6;
$skf_secondary: #F17E3A;
$skf_card_bg: #F7F7F7;
$skf_grey: #B7B5B5;
$skf_heading :#0E53C9;
$skf-label: #4E4E4E;
$normalw: 1450px;
$x-normalw: 1020px;
$tabletw: 925px;
$ipadw: 800px;
$mobilew: 767px;
$x-mobilew: 300px;
$headerW: 1000px;
